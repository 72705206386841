import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IPendingDepositsApi } from './types';

// ==========================================:
export const pendingDeposits: IPendingDepositsApi = {
	getPendingDeposits: (params) =>
		http.get(endpoint.pendingDeposits.ALL, { params }).then((response) => response.data),
	approve: (payload) =>
		http.put(endpoint.pendingDeposits.APPROVE, payload).then((response) => response.data),
	reject: (payload) =>
		http.put(endpoint.pendingDeposits.REJECT, payload).then((response) => response.data),
};
