/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
	ITokenManagementEditParams,
	ITokenManagementParams,
	ITokenManagementResponse,
	ITokenManagementStore,
	ITokenManagementMintPayload,
	ITokenManagementCheckPayload,
} from './types';

// ==========================================:
export const initialState: ITokenManagementStore = {
	tokenManagement: null,
	tokenLoader: false,
	tokenEditLoader: false,
	tokenUpdate: false,
	tokenManagementHistory: null,
};

// ==========================================:
const tokenManagement = createSlice({
	name: '@@tokenManagement',
	initialState,
	reducers: {
		tokenManagementRequest: (state, action: PayloadAction<ITokenManagementParams>) => {
			const stateData = state;
			stateData.tokenManagement = null;
			stateData.tokenLoader = true;
		},
		tokenManagementSuccess: (state, action: PayloadAction<ITokenManagementResponse>) => {
			const { payload } = action;
			const stateData = state;

			stateData.tokenManagement = payload;
			stateData.tokenLoader = false;
		},
		tokenManagementEditRequest: (state, action: PayloadAction<ITokenManagementEditParams>) => {
			const stateData = state;
			stateData.tokenEditLoader = true;
		},
		tokenManagementEditSuccess: (state, action: any) => {
			const stateData = state;

			const result = stateData.tokenManagement.map((obj: any) => {
				return obj.network_id === action.payload.network_id ? { ...obj, ...action.payload } : obj;
			});
			stateData.tokenManagement = result;
			stateData.tokenEditLoader = false;
		},
		tokenManagementHistoryRequest: (state, action) => {
			const stateData = state;

			stateData.tokenLoader = true;
		},
		tokenManagementHistorySuccess: (state, { payload }) => {
			const stateData = state;

			stateData.tokenLoader = false;
			stateData.tokenManagementHistory = payload;
		},
		tokenManagementMintRequest: (
			state,
			{ payload }: PayloadAction<ITokenManagementMintPayload>,
		) => {},
		tokenManagementBurnRequest: (
			state,
			{ payload }: PayloadAction<ITokenManagementMintPayload>,
		) => {},
		tokenManagementCheckRequest: (
			state,
			{ payload }: PayloadAction<ITokenManagementCheckPayload>,
		) => {},
		tokenManagementInitState: () => initialState,
	},
});

export default tokenManagement.reducer;
export const {
	tokenManagementRequest,
	tokenManagementSuccess,
	tokenManagementInitState,
	tokenManagementEditRequest,
	tokenManagementEditSuccess,
	tokenManagementHistoryRequest,
	tokenManagementHistorySuccess,
	tokenManagementMintRequest,
	tokenManagementBurnRequest,
	tokenManagementCheckRequest,
} = tokenManagement.actions;
