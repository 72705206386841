/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IReferralTransactionsRequestPayload,
	IConvertsTransactionsRequestPayload,
	IConvertsTransactionsResponsePayload,
	IReferralTransactionsResponsePayload,
	ICryptoTransactionsRequestPayload,
	ICryptoTransactionsResponsePayload,
	IFiatTransactionsRequestPayload,
	IFiatTransactionsResponsePayload,
	ITransfersTransactionsRequestPayload,
	ITransfersTransactionsResponsePayload,
	IWithdrawalsTransactionsRequestPayload,
	IWithdrawalsTransactionsResponsePayload,
	ICreateTransactionBankAccountRequest,
	ITransfersTransactionsItem,
	ICreateTransactionCryptoPayload,
	IGameTransactionsRequestPayload,
	IGameTransactionsResponsePayload,
} from './types';
import {
	getReferralTransactionsRequest,
	getReferralTransactionsSuccess,
	getConvertsTransactionsRequest,
	getConvertsTransactionsSuccess,
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	getFiatTransactionsRequest,
	getFiatTransactionsSuccess,
	getTransfersTransactionsRequest,
	getTransfersTransactionsSuccess,
	getWithdrawalsTransactionsFiatRequest,
	getWithdrawalsTransactionsFiatSuccess,
	getWithdrawalsTransactionsRequest,
	getWithdrawalsTransactionsSuccess,
	getGameTransactionsRequest,
	getGameTransactionsSuccess,
	transactionsInitState,
	createTransaction,
	createTransactionSuccess,
	createTransactionCrypto,
	createTransactionError,
} from './reducer';

// ===================================================:
function* cryptoTransactionsRequestWorker(
	action: PayloadAction<ICryptoTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICryptoTransactionsResponsePayload = yield call(
			api.transactions.getCryptoTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getCryptoTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* fiatTransactionsRequestWorker(action: PayloadAction<IFiatTransactionsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: IFiatTransactionsResponsePayload = yield call(
			api.transactions.getFiatTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getFiatTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* withdrawalsTransactionsRequestWorker(
	action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IWithdrawalsTransactionsResponsePayload = yield call(
			api.transactions.getWithdrawalsTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getWithdrawalsTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}
// new
function* withdrawalsTransactionsFiatRequestWorker(
	action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IWithdrawalsTransactionsResponsePayload = yield call(
			api.transactions.getWithdrawalsTransactionsFiat,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getWithdrawalsTransactionsFiatSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}
//

// ===================================================:

function* transfersTransactionsRequestWorker(
	action: PayloadAction<ITransfersTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITransfersTransactionsResponsePayload = yield call(
			api.transactions.getTransfersTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getTransfersTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* convertsTransactionsRequestWorker(
	action: PayloadAction<IConvertsTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IConvertsTransactionsResponsePayload = yield call(
			api.transactions.getConvertsTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getConvertsTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* referralTransactionsRequestWorker(
	action: PayloadAction<IReferralTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IReferralTransactionsResponsePayload = yield call(
			api.transactions.getReferralTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getReferralTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* gameTransactionsRequestWorker(action: PayloadAction<IGameTransactionsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IGameTransactionsResponsePayload = yield call(
			api.transactions.getGameTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer(
				'Transaction history successfully exported, check your mailbox!',
				'info',
			);
		else yield put(getGameTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* createTransactiontWorker({
	payload,
}: PayloadAction<ICreateTransactionBankAccountRequest>) {
	const { payload: data, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		const response: ITransfersTransactionsItem = yield call(
			api.transactions.createBankAccount,
			data,
		);
		onSuccess?.();
		yield put(createTransactionSuccess(response));
	} catch (error) {
		yield put(createTransactionError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			onError?.();
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createTransactionCryptoWorker({
	payload,
}: PayloadAction<ICreateTransactionCryptoPayload>) {
	const { payload: data, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		const response: ITransfersTransactionsItem = yield call(
			api.transactions.createBankAccountCrypto,
			data,
		);
		onSuccess?.();
		yield put(createTransactionSuccess(response));
	} catch (error) {
		yield put(createTransactionError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			onError?.();
		}
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:

export function* transactionsSaga() {
	yield takeEvery(getCryptoTransactionsRequest.type, cryptoTransactionsRequestWorker);
	yield takeEvery(getFiatTransactionsRequest.type, fiatTransactionsRequestWorker);
	yield takeEvery(getWithdrawalsTransactionsRequest.type, withdrawalsTransactionsRequestWorker);
	yield takeEvery(
		getWithdrawalsTransactionsFiatRequest.type,
		withdrawalsTransactionsFiatRequestWorker,
	);
	yield takeEvery(getTransfersTransactionsRequest.type, transfersTransactionsRequestWorker);
	yield takeEvery(getConvertsTransactionsRequest.type, convertsTransactionsRequestWorker);
	yield takeEvery(getReferralTransactionsRequest.type, referralTransactionsRequestWorker);
	yield takeEvery(getGameTransactionsRequest.type, gameTransactionsRequestWorker);
	yield takeEvery(createTransaction.type, createTransactiontWorker);
	yield takeEvery(createTransactionCrypto.type, createTransactionCryptoWorker);
}
