import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';

import 'react-tabs/style/react-tabs.css';
import {
	USER_STATUS_APPROVED,
	USER_STATUS_APPROVED_CLASS,
	USER_STATUS_PENDING,
	USER_STATUS_PENDING_CLASS,
	USER_STATUS_REJECTED,
	USER_STATUS_REJECTED_CLASS,
	USER_STATUS_UNVERIFIED,
	USER_STATUS_UNVERIFIED_CLASS,
} from 'redux/reducers/userManagement/constants';
import {
	getUserBalancesRequest,
	getUserRequest,
	setOneUserDateRange,
	setTabMenuUserIndex,
	unsetOneUserDateRange,
} from 'redux/reducers/userManagement/reducer';
import {
	getOneUserDateRange,
	getSelectUser,
	getSelectUserIsLoad,
	tabMenuUserIndex,
} from 'redux/reducers/userManagement/selectors';
import { IUserRequestPayload } from 'redux/reducers/userManagement/types';
import Loader from 'ui/Loader';
import { WhitelistingIP } from './WhitelistingIP';
import { LoginHistory } from './LoginHistory';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import { Spot } from './Spot';
import { Account } from './Account';
import { Margin } from './Margin/Margin';
import { P2P } from './P2P/P2P';
import { TransactionHistory } from './TransactionHistory/TransactionHistory';

const LOGIN_HISTORY_PAGE_INDEX = 2;

export const SelectUser: FC = () => {
	const dispatch = useDispatch();
	const params = useParams<IUserRequestPayload>();
	const { id } = params;
	const selectUser = useSelector(getSelectUser);
	const selectUserLoader = useSelector(getSelectUserIsLoad);
	const [indexMenu, setIndexMenu] = useState(0);
	const tabsCheckIndex = useSelector(tabMenuUserIndex);
	const status = selectUser?.selectUser?.status?.name ?? 'Unverified';
	const statusText = `${status[0].toUpperCase()}${status.slice(1)}`;

	const oneUserDateRange = useSelector(getOneUserDateRange);
	const { afterToday } = DateRangePicker;
	const prevMonthDate = new Date();
	prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);

	useEffect(() => {
		if (tabsCheckIndex === 0) {
			setIndexMenu(0);
			dispatch(setTabMenuUserIndex(null));
		}
	}, [tabsCheckIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getUserRequest({ id }));
		dispatch(getUserBalancesRequest({ id }));
	}, [dispatch, id]);

	useEffect(() => {
		return () => {
			dispatch(unsetOneUserDateRange());
		};
	}, [indexMenu, dispatch]);

	const dateRangeValue = useMemo(() => {
		if (!oneUserDateRange) return null;

		const [startDateStr, endDateStr] = oneUserDateRange;

		return [new Date(`${String(startDateStr)}Z`), new Date(`${String(endDateStr)}Z`)] as DateRange;
	}, [oneUserDateRange]);

	const handleDateRangeChange = (range: DateRange | null) => {
		const formatDateToString = (date: Date) => {
			return date.toISOString().slice(0, 19).split('T').join(' ');
		};

		if (!range) {
			dispatch(unsetOneUserDateRange());
			return;
		}

		const [startDate, endDate] = range;
		startDate.setHours(0, 0, 0);
		endDate.setHours(23, 59, 59);

		const startDateStr = formatDateToString(startDate);
		const endDateStr = formatDateToString(endDate);

		dispatch(setOneUserDateRange([startDateStr, endDateStr]));
	};

	let statusClassName;
	switch (status) {
		case USER_STATUS_APPROVED:
			statusClassName = USER_STATUS_APPROVED_CLASS;
			break;
		case USER_STATUS_PENDING:
			statusClassName = USER_STATUS_PENDING_CLASS;
			break;
		case USER_STATUS_UNVERIFIED:
			statusClassName = USER_STATUS_UNVERIFIED_CLASS;
			break;
		case USER_STATUS_REJECTED:
		default:
			statusClassName = USER_STATUS_REJECTED_CLASS;
	}

	return (
		<>
			{!selectUser && !selectUserLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">No user found...</p>
				</div>
			)}
			{selectUserLoader ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<>
					<div className="panel panel--margin-none select-user-header">
						<div className="user-block">
							<p className="user-block__name">{selectUser.selectUser?.username}</p>
							<span className={`status ${statusClassName}`}>{statusText}</span>
						</div>

						{indexMenu === LOGIN_HISTORY_PAGE_INDEX && (
							<DateRangePicker
								format="dd/MM/yyyy"
								size="lg"
								block
								placement="bottomEnd"
								disabledDate={afterToday?.()}
								value={dateRangeValue}
								onChange={handleDateRangeChange}
								defaultCalendarValue={[prevMonthDate, prevMonthDate]}
								ranges={[]}
							/>
						)}
					</div>

					<Tabs selectedIndex={indexMenu} onSelect={setIndexMenu} className="settings-tabs">
						<TabList className="user-settings user-settings--settings">
							<Tab className="user-settings__item">
								<span className="user-settings__link">Account </span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">IP & Whitelisting</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Login history</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Spot</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Margin</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">P2P</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Transaction history</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Payment Method</span>
							</Tab>
						</TabList>
						<TabPanel>
							<Account />
						</TabPanel>
						<TabPanel>
							<WhitelistingIP />
						</TabPanel>
						<TabPanel>
							<LoginHistory />
						</TabPanel>
						<TabPanel>
							<Spot />
						</TabPanel>
						<TabPanel>
							<Margin />
						</TabPanel>
						<TabPanel>
							<P2P />
						</TabPanel>
						<TabPanel>
							<TransactionHistory />
						</TabPanel>
						<TabPanel>
							<PaymentMethod />
						</TabPanel>
					</Tabs>
				</>
			)}
		</>
	);
};
