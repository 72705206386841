import { FC } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { getPendingDepositsLoad } from 'redux/reducers/pendingDeposits/selectors';
import { DepositItem } from './DepositItem';
import { IDepositsListProps } from './types';

const DepositsList: FC<IDepositsListProps> = ({ deposits, handleClearSearch }) => {
	const loading = useSelector(getPendingDepositsLoad);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--pending-deposits">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>User id</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Game User ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Time</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Asset</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Amount</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Amount in USD</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!deposits?.length && !loading && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No deposits found...</p>
						</div>
					)}
					{loading ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{deposits?.map((item) => (
								<DepositItem key={item.id} data={item} handleClearSearch={handleClearSearch} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DepositsList;
