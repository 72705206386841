import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportIcon } from 'assets/img/icons';
import {
	tokenManagementEditRequest,
	tokenManagementHistoryRequest,
	tokenManagementRequest,
} from 'redux/reducers/tokenManagement/reducer';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useLocation } from 'react-router';
import {
	getManagementStore,
	getManagementStoreLoading,
	getManagementStoreUpdate,
	getManagementHistory,
} from 'redux/reducers/tokenManagement/selectors';
import Item from './TokenManagement/Item';
import {
	ITokenManagementEditParams,
	ITokenManagementResponse,
} from '../../redux/reducers/tokenManagement/types';
import Loader from '../../ui/Loader';
import HistoryTab from './HistoryTab';

const TokenManagement: FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(getManagementStore);
	const loading = useSelector(getManagementStoreLoading);
	const tokenHistory = useSelector(getManagementHistory);
	const [activeTub, setActiveTub] = useState('GEL');
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	useEffect(() => {
		if (activeTub === 'USD' || activeTub === 'GEL' || activeTub === 'EUR' || activeTub === 'GOLD') {
			dispatch(tokenManagementRequest({ code: `${activeTub.toLowerCase()}b` }));
			setCurrentPage(1);
		}
		if (activeTub === 'History') {
			dispatch(tokenManagementHistoryRequest({ current_page: 1, per_page: 20 }));
		}
	}, [dispatch, activeTub]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const params: any = {
			current_page: pageNumber || 1,
			per_page: 20,
		};

		dispatch(tokenManagementHistoryRequest(params));
	};

	const editTokenManagement = (editData: ITokenManagementEditParams) => {
		dispatch(tokenManagementEditRequest(editData));
	};

	const handleExport = () => {
		const params: any = {
			current_page: currentPage || 1,
			export: 1,
		};
		dispatch(tokenManagementHistoryRequest(params));
	};

	return (
		<>
			<div className="title-block title-block--admin-managment">
				<p className="title">Token Management</p>
			</div>

			<div className="count-label count-label--filter-type">
				{activeTub === 'History' ? (
					<button
						type="button"
						className="transaction-export-btn"
						onClick={handleExport}
						// disabled={isExporting}
					>
						Export history
						<span className="transaction-export-btn__icon">
							<ExportIcon />
						</span>
					</button>
				) : (
					''
				)}
			</div>

			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<ul className="tabs-list tabs-list--type2">
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'GEL' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('GEL');
								}}
								className="tabs-list__link"
							>
								GEL
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'EUR' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('EUR');
								}}
								className="tabs-list__link"
							>
								EUR
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'USD' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('USD');
								}}
								className="tabs-list__link"
							>
								USD
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'GOLD' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('GOLD');
								}}
								className="tabs-list__link"
							>
								GOLD
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'History' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('History');
								}}
								className="tabs-list__link"
							>
								History
							</span>
						</li>
					</ul>
					{data &&
					(activeTub === 'USD' ||
						activeTub === 'GEL' ||
						activeTub === 'EUR' ||
						activeTub === 'GOLD')
						? data?.map((e: ITokenManagementResponse) => {
								return (
									<Item
										key={`${e.asset_id}${e.network_id}`}
										item={e}
										editTokenManagement={editTokenManagement}
										currency={String(activeTub?.toLowerCase())}
									/>
								);
						  })
						: null}
					{activeTub === 'History' && <HistoryTab />}
					{loading ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : null}
				</div>
			</div>
			{activeTub === 'History' && (tokenHistory?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={tokenHistory?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default TokenManagement;
