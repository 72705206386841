/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { DateRangePicker } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { useDateTimePicker } from 'services/hooks/useDateTimePicker';
import { getPendingDepositsRequest, setFilterParams } from 'redux/reducers/pendingDeposits/reducer';
import { IGetPendingDepositsRequest } from 'redux/reducers/pendingDeposits/types';
import { getPendingDeposits, getFilterParams } from 'redux/reducers/pendingDeposits/selectors';
import {
	PENDING_DEPOSITS_SEARCH_ARRAY,
	PENDING_DEPOSITS_SEARCH_TEXT_ARRAY,
} from 'redux/reducers/pendingDeposits/constants';
import SearchBar from 'components/SearchBar';
import DepositsList from './DepositsList';

const PendingDeposits = () => {
	const deposits = useSelector(getPendingDeposits);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(
		field ? String(field) : PENDING_DEPOSITS_SEARCH_ARRAY[0],
	);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
		const params: IGetPendingDepositsRequest = {
			current_page: 1,
			search_field: searchField,
			search_value: String(text) || undefined,
			start_date,
			end_date,
		};

		const searchParams =
			String(text).length > 0
				? `?page=1${String(text) && `&text=${String(text)}&field=${searchField}`}`
				: `?page=1`;

		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getPendingDepositsRequest(params));
	};

	const { afterToday } = DateRangePicker;

	const { dateRangeValue, changeDateRangeHandler } = useDateTimePicker({
		onDateSelect: handleDataSelect,
	});

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IGetPendingDepositsRequest =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
						start_date: startDate || null,
						end_date: endDate || null,
				  }
				: {
						current_page: pageNumber || 1,
						start_date: startDate || null,
						end_date: endDate || null,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getPendingDepositsRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
			start_date: startDate || null,
			end_date: endDate || null,
		};

		if (txt.length >= 1) {
			dispatch(getPendingDepositsRequest(params));
		} else {
			dispatch(getPendingDepositsRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?page=1&text=${String(txt)}&field=${searchField}` : ``,
		});
		setCurrentPage(1);
	};
	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 400);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	const searchHandler = (txt: string) => {
		setDebouncedTerm(txt);
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<>
			<div className="title-block">
				<p className="title">Pending deposits</p>
			</div>
			<div className="count-label count-label--filter-type">
				<SearchBar
					setDebouncedTerm={searchHandler}
					debouncedTerm={debouncedTerm}
					searchFieldArray={PENDING_DEPOSITS_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={PENDING_DEPOSITS_SEARCH_TEXT_ARRAY}
				/>
				<DateRangePicker
					format="dd/MM/yyyy"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '308px' }}
					disabledDate={afterToday?.()}
					value={dateRangeValue}
					onChange={changeDateRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
				/>
			</div>

			<DepositsList deposits={deposits?.data} handleClearSearch={handleClearSearch} />

			{(deposits?.total ?? 0) > 1 && (
				<Pagination
					pageCount={deposits?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default PendingDeposits;
