import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IWithdrawalsData, IWithdrawalsStore, IGetBankTransferResponse } from './types';

// ==========================================:
const getWithdrawalsState = (state: IStoreState): IWithdrawalsStore => state.withdrawals;
export const getWithdrawals = createSelector(
	[getWithdrawalsState],
	(withdrawals: IWithdrawalsStore) => withdrawals,
);

// ====================================================:
export const getWithdrawalsIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.withdrawalsLoader,
);

// ====================================================:
export const getWithdrawalsList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IWithdrawalsData => withdrawals.withdrawals,
);

// ====================================================:
export const getFiatWithdrawalsList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore) => withdrawals.withdrawalsFiat,
);

// ====================================================:
export const getWithdrawalsFiatIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.withdrawalsFiatLoader,
);

// ====================================================:
export const getBankTransferIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.bankTransferLoader,
);

// ====================================================:
export const getBankTransfers = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IGetBankTransferResponse | null => withdrawals.bankTransfer,
);
