import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getPendingDepositsRequest,
	getPendingDepositsSuccess,
	approveDepositRequest,
	approveDepositSuccess,
	rejectDepositRequest,
	rejectDepositSuccess,
} from './reducer';
import {
	IGetPendingDepositsRequest,
	IGetPendingDepositsResponse,
	IApproveRequestPayload,
	IRejectRequestPayload,
} from './types';
// =============================================================:

function* getPendingDepositsRequestWorker(action: PayloadAction<IGetPendingDepositsRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IGetPendingDepositsResponse = yield call(
			api.pendingDeposits.getPendingDeposits,
			payload,
		);
		yield put(getPendingDepositsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* approveDepositRequestWorker({ payload }: PayloadAction<IApproveRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.approve, apiParams);
		yield put(approveDepositSuccess({ id: apiParams.id }));
		onSuccess?.();
		notificationContainer('Deposit successfully approved', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* rejectDepositRequestWorker({ payload }: PayloadAction<IRejectRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.reject, apiParams);
		yield put(rejectDepositSuccess({ id: apiParams.id }));
		onSuccess?.();
		notificationContainer('Deposit successfully rejected', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* pendingDepositsSaga() {
	yield takeEvery(getPendingDepositsRequest.type, getPendingDepositsRequestWorker);
	yield takeEvery(approveDepositRequest.type, approveDepositRequestWorker);
	yield takeEvery(rejectDepositRequest.type, rejectDepositRequestWorker);
}
