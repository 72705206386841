/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IReferralTransactionsRequestPayload,
	IReferralTransactionsResponsePayload,
	IConvertsTransactionsRequestPayload,
	IConvertsTransactionsResponsePayload,
	ICryptoTransactionsRequestPayload,
	ICryptoTransactionsResponsePayload,
	IFiatTransactionsRequestPayload,
	IFiatTransactionsResponsePayload,
	ITransactionsStore,
	ITransfersTransactionsRequestPayload,
	ITransfersTransactionsResponsePayload,
	IWithdrawalsTransactionsRequestPayload,
	IWithdrawalsTransactionsResponsePayload,
	ICreateTransactionBankAccountRequest,
	ITransfersTransactionsItem,
	ICreateTransactionCryptoPayload,
	IGameTransactionsRequestPayload,
	IGameTransactionsResponsePayload,
} from './types';
// ==========================================:
export const initialState: ITransactionsStore = {
	cryptoTransactions: null,
	cryptoTransactionsLoader: false,
	fiatTransactions: null,
	fiatTransactionsLoader: false,
	withdrawalsTransactions: null,
	withdrawalsTransactionsLoader: false,
	//
	withdrawalsTransactionsFiat: null,
	withdrawalsTransactionsFiatLoader: false,
	//
	transfersTransactions: null,
	transfersTransactionsLoader: false,
	convertsTransactions: null,
	convertsTransactionsLoader: false,
	referralTransactions: null,
	referralTransactionsLoader: false,
	gameTransactions: null,
	gameTransactionsLoader: false,
	//
	transactionData: null,
	createTransactionLoading: false,
};

// ==========================================:
const transactions = createSlice({
	name: '@@Transactions',
	initialState,
	reducers: {
		getCryptoTransactionsRequest: (
			state,
			action: PayloadAction<ICryptoTransactionsRequestPayload>,
		) => {
			const cryptoTransactionsRequestState = state;
			if (action.payload.export !== 1)
				cryptoTransactionsRequestState.cryptoTransactionsLoader = true;
		},
		getCryptoTransactionsSuccess: (
			state,
			action: PayloadAction<ICryptoTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const cryptoTransactionsSuccessState = state;
			cryptoTransactionsSuccessState.cryptoTransactions = payload;
			cryptoTransactionsSuccessState.cryptoTransactionsLoader = false;
		},
		getFiatTransactionsRequest: (state, action: PayloadAction<IFiatTransactionsRequestPayload>) => {
			const fiatTransactionsRequestState = state;
			if (action.payload.export !== 1) fiatTransactionsRequestState.fiatTransactionsLoader = true;
		},
		getFiatTransactionsSuccess: (
			state,
			action: PayloadAction<IFiatTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const fiatTransactionsSuccessState = state;
			fiatTransactionsSuccessState.fiatTransactions = payload;
			fiatTransactionsSuccessState.fiatTransactionsLoader = false;
		},
		getWithdrawalsTransactionsRequest: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
		) => {
			const withdrawalsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				withdrawalsTransactionsRequestState.withdrawalsTransactionsLoader = true;
		},
		getWithdrawalsTransactionsSuccess: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const withdrawalsTransactionsSuccessState = state;
			withdrawalsTransactionsSuccessState.withdrawalsTransactions = payload;
			withdrawalsTransactionsSuccessState.withdrawalsTransactionsLoader = false;
		},
		//
		getWithdrawalsTransactionsFiatRequest: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
		) => {
			const withdrawalsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				withdrawalsTransactionsRequestState.withdrawalsTransactionsFiatLoader = true;
		},
		getWithdrawalsTransactionsFiatSuccess: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const withdrawalsTransactionsSuccessState = state;
			withdrawalsTransactionsSuccessState.withdrawalsTransactionsFiat = payload;
			withdrawalsTransactionsSuccessState.withdrawalsTransactionsFiatLoader = false;
		},
		//
		getTransfersTransactionsRequest: (
			state,
			action: PayloadAction<ITransfersTransactionsRequestPayload>,
		) => {
			const transfersTransactionsRequestState = state;
			if (action.payload.export !== 1)
				transfersTransactionsRequestState.transfersTransactionsLoader = true;
		},
		getTransfersTransactionsSuccess: (
			state,
			action: PayloadAction<ITransfersTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const transfersTransactionsSuccessState = state;
			transfersTransactionsSuccessState.transfersTransactions = payload;
			transfersTransactionsSuccessState.transfersTransactionsLoader = false;
		},
		getConvertsTransactionsRequest: (
			state,
			action: PayloadAction<IConvertsTransactionsRequestPayload>,
		) => {
			const convertsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				convertsTransactionsRequestState.convertsTransactionsLoader = true;
		},
		getConvertsTransactionsSuccess: (
			state,
			action: PayloadAction<IConvertsTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const convertsTransactionsSuccessState = state;
			convertsTransactionsSuccessState.convertsTransactions = payload;
			convertsTransactionsSuccessState.convertsTransactionsLoader = false;
		},

		getReferralTransactionsRequest: (
			state,
			action: PayloadAction<IReferralTransactionsRequestPayload>,
		) => {
			const convertsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				convertsTransactionsRequestState.referralTransactionsLoader = true;
		},
		getReferralTransactionsSuccess: (
			state,
			action: PayloadAction<IReferralTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const convertsTransactionsSuccessState = state;
			convertsTransactionsSuccessState.referralTransactions = payload;
			convertsTransactionsSuccessState.referralTransactionsLoader = false;
		},

		getGameTransactionsRequest: (state, action: PayloadAction<IGameTransactionsRequestPayload>) => {
			const transactionsSuccessState = state;
			if (action.payload.export !== 1) transactionsSuccessState.gameTransactionsLoader = true;
		},
		getGameTransactionsSuccess: (
			state,
			action: PayloadAction<IGameTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const transactionsSuccessState = state;
			transactionsSuccessState.gameTransactions = payload;
			transactionsSuccessState.gameTransactionsLoader = false;
		},

		transactionsInitState: () => initialState,
		createTransaction: (state, action: PayloadAction<ICreateTransactionBankAccountRequest>) => {
			const transactionState = state;
			transactionState.createTransactionLoading = true;
		},
		createTransactionCrypto: (state, action: PayloadAction<ICreateTransactionCryptoPayload>) => {
			const transactionState = state;
			transactionState.createTransactionLoading = true;
		},
		createTransactionSuccess: (state, action: PayloadAction<ITransfersTransactionsItem>) => {
			const { payload } = action;
			const transaction = state;
			transaction.transactionData = payload;
			transaction.createTransactionLoading = false;
		},
		createTransactionError: (state) => {
			const transactionState = state;
			transactionState.createTransactionLoading = false;
		},
	},
});

export default transactions.reducer;
export const {
	getReferralTransactionsRequest,
	getReferralTransactionsSuccess,
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	getFiatTransactionsRequest,
	getFiatTransactionsSuccess,
	getWithdrawalsTransactionsRequest,
	getWithdrawalsTransactionsSuccess,
	getTransfersTransactionsRequest,
	getTransfersTransactionsSuccess,
	getConvertsTransactionsRequest,
	getConvertsTransactionsSuccess,
	getWithdrawalsTransactionsFiatRequest,
	getWithdrawalsTransactionsFiatSuccess,
	getGameTransactionsRequest,
	getGameTransactionsSuccess,
	createTransaction,
	createTransactionSuccess,
	createTransactionCrypto,
	createTransactionError,
	transactionsInitState,
} = transactions.actions;
