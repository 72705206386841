import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IPendingDepositsStore } from './types';

const getPendingDepositsState = (state: IStoreState): IPendingDepositsStore =>
	state.pendingDeposits;

export const getPendingDepositsLoad = createSelector(
	[getPendingDepositsState],
	(trades: IPendingDepositsStore) => trades.pendingDepositsLoading,
);

export const getPendingDeposits = createSelector(
	[getPendingDepositsState],
	(trades: IPendingDepositsStore) => trades.pendingDeposits,
);

export const getFilterParams = createSelector(
	[getPendingDepositsState],
	(account: IPendingDepositsStore) => account.filterParams,
);
