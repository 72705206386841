import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import { getParameterByName } from 'utils/paramNames';
import {
	getUserBalancesRequest,
	getUserBalancesSuccess,
	getUsersRequest,
	getUsersSuccess,
	usersInitState,
	setUserBlockUnblockSuccess,
	setUserBlockUnblockRequest,
	get2FASuccess,
	get2FARequest,
	reset2FA,
	getUserSuccess,
	getUserRequest,
	reset2FASuccess,
	reject2FASuccess,
	reject2FA,
	getWhitelistIpSuccess,
	getWhitelistIpRequest,
	removeWhitelistIpSuccess,
	removeWhitelistIpRequest,
	verifyWhitelistIpSuccess,
	verifyWhitelistIpRequest,
	getLoginHistorySuccess,
	getLoginHistoryRequest,
	getDepositAndWithdrawHistorySuccess,
	getDepositAndWithdrawHistoryRequest,
	getUserOrdersHistoryRequest,
	getUserOrdersHistorySuccess,
	getUserMarginOrdersHistoryRequest,
	getUserMarginOrdersHistorySuccess,
	getUserMarginBorrowingRequest,
	getUserMarginBorrowingSuccess,
	getUserMarginRepaymentRequest,
	getUserMarginRepaymentSuccess,
	getUserMarginInterestRequest,
	getUserMarginInterestSuccess,
	getUserMarginLiquidationRequest,
	getUserMarginLiquidationSuccess,
	getUserMarginBalancesSuccess,
	getUserMarginBalancesRequest,
	getUserMarginMarginCallRequest,
	getUserMarginMarginCallSuccess,
	getUserAdvertisementsP2PSuccess,
	getUserAdvertisementsP2PRequest,
	getUserDisputeP2PSuccess,
	getUserDisputeP2PRequest,
	getUserTradesP2PSuccess,
	getUserTradesP2PRequest,
	getUserCryptoDepositRequest,
	getUserCryptoDepositSuccess,
	getUserFiatDepositRequest,
	getUserFiatDepositSuccess,
	getUserConvertsRequest,
	getUserConvertsSuccess,
	getUserTransfersRequest,
	getUserTransfersSuccess,
	setUserUpdateVipStatusSuccess,
	setUserUpdateVipStatusRequest,
	getUserCardsRequest,
	getUserCardsSuccess,
	getUserBankAccountRequest,
	getUserBankAccountSuccess,
	getUserBalancesP2PRequest,
	getUserBalancesP2PSuccess,
	updateReferralFeeRequest,
	updateReferralFeeSuccess,
	updateBankAccountStatusRequest,
	updateBankAccountStatusSuccess,
	updateBankAccountStatusError,
	getEmail2FARequest,
	getEmail2FASuccess,
	getEmail2FAError,
	getSms2FARequest,
	getSms2FASuccess,
	getSms2FAError,
} from './reducer';
import {
	IUsersResponsePayload,
	IUsersListRequestPayload,
	IUserBalancesRequestPayload,
	IUserBalancesResponsePayload,
	IUserBlockUnblockRequestPayload,
	IUserBlockUnblockResponsePayload,
	I2FAListRequestPayload,
	I2FAResponsePayload,
	ISelectUserResponsePayload,
	IUserRequestPayload,
	I2FAResetPayload,
	I2FARejectPayload,
	IWhitelistIpListRequestPayload,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveRequestPayload,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpVerifyRequestPayload,
	IWhitelistIpVerifyResponsePayload,
	ILoginHistoryRequestPayload,
	ILoginHistoryResponsePayload,
	IDepositAndWithdrawHistoryRequestPayload,
	IDepositAndWithdrawHistoryResponsePayload,
	IMarginBorrowingPayload,
	IUserUpdateVipStatusRequestData,
	IUserItem,
	IUserCardsRequest,
	IUserBankAccountRequest,
	IUserCardsResponse,
	IUserBankAccountResponse,
	TBalancesP2PResponse,
	IReferralUpdateParams,
	IUpdateBankAccountStatusPayload,
	IUserBankAccountItem,
	IEmail2FARequest,
	IEmail2Fa,
	ISms2Fa,
} from './types';

// =============================================================:
function* usersRequestWorker(action: PayloadAction<IUsersListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IUsersResponsePayload = yield call(api.users.getUsers, payload);
		if (payload.export === 1) {
			notificationContainer('Transactions successfully exported, check your mailbox!', 'info');
		} else yield put(getUsersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* userRequestWorker(action: PayloadAction<IUserRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ISelectUserResponsePayload = yield call(api.users.getUser, payload);
		yield put(getUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* userBalancesRequestWorker(action: PayloadAction<IUserBalancesRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getBalances, payload);

		yield put(getUserBalancesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userOrdersHistoryRequestWorker(action: PayloadAction<IUserBalancesRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getOrdersHistory, payload);

		yield put(getUserOrdersHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userOrdersHistoryMarginRequestWorker(action: PayloadAction<IUserBalancesRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(
			api.users.getOrdersHistoryMargin,
			payload,
		);
		yield put(getUserMarginOrdersHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* whitelistIpRequestWorker(action: PayloadAction<IWhitelistIpListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWhitelistIpListResponsePayload = yield call(api.users.getWhitelistIp, payload);

		yield put(getWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* whitelistIpRemoveRequestWorker(action: PayloadAction<IWhitelistIpRemoveRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		const response: IWhitelistIpRemoveResponsePayload = yield call(
			api.users.removeWhitelistIp,
			payload,
		);

		yield put(removeWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWhitelistIpRequest({ id: String(payload.userId), current_page: pageNumber }));
	}
}

// =============================================================:
function* whitelistIpVerifyRequestWorker(action: PayloadAction<IWhitelistIpVerifyRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		const response: IWhitelistIpVerifyResponsePayload = yield call(
			api.users.verifyWhitelistIp,
			payload,
		);

		yield put(verifyWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWhitelistIpRequest({ id: String(payload.userId), current_page: pageNumber }));
	}
}

// =============================================================:
function* userBlockUnblockRequestWorker(action: PayloadAction<IUserBlockUnblockRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		const response: IUserBlockUnblockResponsePayload = yield call(
			api.users.setUserBlockUnblock,
			payload,
		);
		yield put(getUsersRequest({ current_page: pageNumber }));
		yield put(setUserBlockUnblockSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* userUpdateVipStatusRequestWorker({
	payload,
}: PayloadAction<IUserUpdateVipStatusRequestData>) {
	try {
		yield put(showLoading());
		const response: IUserItem = yield call(api.users.setUserUpdateVipStatus, payload);
		yield put(setUserUpdateVipStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
// export function* userBlockUnblockSaga() {
// 	yield takeEvery(setUserBlockUnblockRequest.type, userBlockUnblockRequestWorker);
// }

// =============================================================:
function* twoFARequestWorker(action: PayloadAction<I2FAListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: I2FAResponsePayload = yield call(api.users.get2FA, payload);
		yield put(get2FASuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* twoFAResetRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		yield call(api.users.reset2FA, payload);
		yield put(reset2FASuccess());
		yield put(get2FARequest({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(get2FARequest({ current_page: pageNumber }));
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* twoFARejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		yield call(api.users.reject2FA, payload);
		yield put(reject2FASuccess());
		yield put(get2FARequest({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(get2FARequest({ current_page: pageNumber }));
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* loginHistoryRequestWorker(action: PayloadAction<ILoginHistoryRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILoginHistoryResponsePayload = yield call(api.users.getLoginHistory, payload);

		yield put(getLoginHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* depositAndWithdrawHistoryRequestWorker(
	action: PayloadAction<IDepositAndWithdrawHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IDepositAndWithdrawHistoryResponsePayload = yield call(
			api.users.getDepositAndWithdrawHistory,
			payload,
		);

		yield put(getDepositAndWithdrawHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userBorrowingMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBorrowingMarginCross,
				payload,
			);
			yield put(getUserMarginBorrowingSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBorrowingMarginIsolated,
				payload,
			);
			yield put(getUserMarginBorrowingSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userRepaymentMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getRepaymentMarginCross,
				payload,
			);
			yield put(getUserMarginRepaymentSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getRepaymentMarginIsolated,
				payload,
			);
			yield put(getUserMarginRepaymentSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userInterestMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getInterestMarginCross,
				payload,
			);
			yield put(getUserMarginInterestSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getInterestMarginIsolated,
				payload,
			);
			yield put(getUserMarginInterestSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userLiquidationMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getLiquidationMarginCross,
				payload,
			);
			yield put(getUserMarginLiquidationSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getLiquidationMarginIsolated,
				payload,
			);
			yield put(getUserMarginLiquidationSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userBalancesMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBalancesMarginCross,
				payload,
			);
			yield put(getUserMarginBalancesSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBalancesMarginIsolated,
				payload,
			);
			yield put(getUserMarginBalancesSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userMarginCallMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getMarginCallMarginCross,
				payload,
			);
			yield put(getUserMarginMarginCallSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getMarginCallMarginIsolated,
				payload,
			);
			yield put(getUserMarginMarginCallSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userAdvertisementsP2PRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(
			api.users.getP2PAdvertisements,
			payload,
		);
		yield put(getUserAdvertisementsP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userDisputeP2PRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getP2PDispute, payload);
		yield put(getUserDisputeP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userTradesP2PRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getP2PTrades, payload);
		yield put(getUserTradesP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userBalancesP2PRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: TBalancesP2PResponse = yield call(api.users.getP2PBalances, payload);
		yield put(getUserBalancesP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userCryptoDepositRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getCryptoDeposit, payload);
		yield put(getUserCryptoDepositSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userFiatDepositRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getFiatDeposit, payload);
		yield put(getUserFiatDepositSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userConvertsRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getConverts, payload);
		yield put(getUserConvertsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userTransfersRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getTransfers, payload);
		yield put(getUserTransfersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* getUserCardsWorker(action: PayloadAction<IUserCardsRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserCardsResponse = yield call(api.users.getUserCards, payload);
		yield put(getUserCardsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* getUserBankAccountWorker(action: PayloadAction<IUserBankAccountRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBankAccountResponse = yield call(api.users.getBankAccount, payload);
		yield put(getUserBankAccountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* updateReferralFeeWorker(action: PayloadAction<IReferralUpdateParams>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.users.updateReferral, payload);
		yield put(updateReferralFeeSuccess(payload));

		// yield put(getUsersRequest({ current_page: 1 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* updateBankAccountStatusWorker({
	payload,
}: PayloadAction<IUpdateBankAccountStatusPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IUserBankAccountItem = yield call(api.users.updateBankAccountStatus, apiParams);
		yield put(updateBankAccountStatusSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(updateBankAccountStatusError());
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* getEmail2FAWorker({ payload }: PayloadAction<IEmail2FARequest>) {
	try {
		yield put(showLoading());
		const response: IEmail2Fa = yield call(api.users.getEmail2FA, payload);
		if (payload.export === 1) {
			notificationContainer('Email 2FA history successfully exported, check your mailbox!', 'info');
		} else yield put(getEmail2FASuccess(response));
	} catch (error) {
		yield put(getEmail2FAError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* getSms2FAWorker({ payload }: PayloadAction<IEmail2FARequest>) {
	try {
		yield put(showLoading());
		const response: ISms2Fa = yield call(api.users.getSms2FA, payload);
		if (payload.export === 1) {
			notificationContainer('Email 2FA history successfully exported, check your mailbox!', 'info');
		} else yield put(getSms2FASuccess(response));
	} catch (error) {
		yield put(getSms2FAError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
// =============================================================:
export function* usersSaga() {
	yield takeEvery(getUsersRequest.type, usersRequestWorker);
	yield takeEvery(getUserRequest.type, userRequestWorker);
	yield takeEvery(getUserBalancesRequest.type, userBalancesRequestWorker);
	yield takeEvery(getUserOrdersHistoryRequest.type, userOrdersHistoryRequestWorker);
	yield takeEvery(getUserMarginOrdersHistoryRequest.type, userOrdersHistoryMarginRequestWorker);
	yield takeEvery(getWhitelistIpRequest.type, whitelistIpRequestWorker);
	yield takeEvery(removeWhitelistIpRequest.type, whitelistIpRemoveRequestWorker);
	yield takeEvery(verifyWhitelistIpRequest.type, whitelistIpVerifyRequestWorker);
	yield takeEvery(setUserBlockUnblockRequest.type, userBlockUnblockRequestWorker);
	yield takeEvery(setUserUpdateVipStatusRequest.type, userUpdateVipStatusRequestWorker);
	yield takeEvery(get2FARequest.type, twoFARequestWorker);
	yield takeEvery(reset2FA.type, twoFAResetRequestWorker);
	yield takeEvery(reject2FA.type, twoFARejectRequestWorker);
	yield takeEvery(getLoginHistoryRequest.type, loginHistoryRequestWorker);
	yield takeEvery(getDepositAndWithdrawHistoryRequest.type, depositAndWithdrawHistoryRequestWorker);
	yield takeEvery(getUserMarginBorrowingRequest.type, userBorrowingMarginRequestWorker);
	yield takeEvery(getUserMarginRepaymentRequest.type, userRepaymentMarginRequestWorker);
	yield takeEvery(getUserMarginInterestRequest.type, userInterestMarginRequestWorker);
	yield takeEvery(getUserMarginLiquidationRequest.type, userLiquidationMarginRequestWorker);
	yield takeEvery(getUserMarginBalancesRequest.type, userBalancesMarginRequestWorker);
	yield takeEvery(getUserMarginMarginCallRequest.type, userMarginCallMarginRequestWorker);
	yield takeEvery(getUserAdvertisementsP2PRequest.type, userAdvertisementsP2PRequestWorker);
	yield takeEvery(getUserDisputeP2PRequest.type, userDisputeP2PRequestWorker);
	yield takeEvery(getUserTradesP2PRequest.type, userTradesP2PRequestWorker);
	yield takeEvery(getUserBalancesP2PRequest.type, userBalancesP2PRequestWorker);
	yield takeEvery(getUserCryptoDepositRequest.type, userCryptoDepositRequestWorker);
	yield takeEvery(getUserFiatDepositRequest.type, userFiatDepositRequestWorker);
	yield takeEvery(getUserConvertsRequest.type, userConvertsRequestWorker);
	yield takeEvery(getUserTransfersRequest.type, userTransfersRequestWorker);
	yield takeEvery(getUserCardsRequest.type, getUserCardsWorker);
	yield takeEvery(getUserBankAccountRequest.type, getUserBankAccountWorker);
	yield takeEvery(updateReferralFeeRequest.type, updateReferralFeeWorker);
	yield takeEvery(updateBankAccountStatusRequest.type, updateBankAccountStatusWorker);
	yield takeEvery(getEmail2FARequest.type, getEmail2FAWorker);
	yield takeEvery(getSms2FARequest.type, getSms2FAWorker);
}
