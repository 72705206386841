export const endpoint = {
	auth: {
		LOGIN: '/admin/login',
		LOGOUT: '/admin/logout',
	},
	settings: {
		CHANGE_PASSWORD: '/admin/password/change',
		ENABLE_2FA: '/admin/2fa/enable',
		DISABLE_2FA: '/admin/2fa/disable',
		GENERATE_2FA_KEY: '/admin/2fa/generate_secret_key',
		CHECK_ENABLED_2FA: '/admin/2fa/check_enabled',
		GET_CONVERT_SETTINGS: '/admin/convert/settings',
		PUT_CONVERT_SETTINGS: '/admin/convert/settings',
	},
	trades: {
		tradesSpot: '/admin/trades',
		tradesP2P: '/admin/p2p/trades',
	},
	orders: {
		ordersSpot: '/admin/orders',
		ordersP2P: '/admin/p2p/orders',
	},
	dispute: {
		disputeP2P: '/admin/p2p/disputes/trades',
		getDetails: (id: string) => `/admin/p2p/disputes/trade/${id}`,
		accept: (id: string) => `/admin/p2p/disputes/trade/${id}/approve`,
		reject: (id: string) => `/admin/p2p/disputes/trade/${id}/reject`,
	},
	currencies: {
		general: '/admin/assets',
		p2p: '/admin/p2p/assets',
		generalChange: '/admin/asset',
		CHANGE_CURRENCY_STATUS: '/admin/p2p/asset',
	},
	users: {
		USERS: '/admin/users',
		USER: '/admin/user/user_settings',
		BALANCES: (id: string) => `/admin/user/${id}/balances`,
		ORDERS_HISTORY: (id: string) => `/admin/user/${id}/orders`,
		USER_BLOCK_UNBLOCK: (id: number) => `/admin/user/${id}/blocked`,
		USER_UPDATE_VIP_STATUS: (id: number) => `/admin/user/${id}/vip`,
		TWO_FA: '/admin/2fa/reset',
		TWO_FA_RESET: '/admin/2fa/reset',
		TWO_FA_REJECT: '/admin/2fa/reject',
		WHITELIST_IP: (id: string) => `/admin/user/${id}/whitelist_ip`,
		WHITELIST_IP_REMOVE: '/admin/whitelist_ip/remove',
		WHITELIST_IP_VERIFY: '/admin/whitelist_ip/verify',
		LOGIN_HISTORY: (id: string) => `/admin/user/${id}/login_history`,
		TRANSACTIONS: (id: string) => `/admin/user/${id}/transactions`,
		ORDERS: (id: string) => `/admin/user/${id}/orders`,
		BORROW_CROSS: (id: string) => `/admin/user/${id}/margin/borrow`,
		BORROW_ISOLATED: (id: string) => `/admin/user/${id}/isolated/borrow`,
		REPAYMENT_CROSS: (id: string) => `/admin/user/${id}/margin/repay`,
		REPAYMENT_ISOLATED: (id: string) => `/admin/user/${id}/isolated/repay`,
		INTEREST_CROSS: (id: string) => `/admin/user/${id}/margin/interest`,
		INTEREST_ISOLATED: (id: string) => `/admin/user/${id}/isolated/interest`,
		LIQUIDATION_CROSS: (id: string) => `/admin/user/${id}/margin/liquidation`,
		LIQUIDATION_ISOLATED: (id: string) => `/admin/user/${id}/isolated/liquidation`,
		BALANCES_CROSS: (id: string) => `/admin/user/${id}/margin/balances`,
		BALANCES_ISOLATED: (id: string) => `/admin/user/${id}/isolated/balances`,
		MARGIN_CALL_CROSS: (id: string) => `/admin/user/${id}/margin/margin_call`,
		MARGIN_CALL_ISOLATED: (id: string) => `/admin/user/${id}/isolated/margin_call`,
		P2P_ADVERTISEMENTS: (id: string) => `/admin/user/${id}/p2p/orders`,
		P2P_DISPUTE: (id: string) => `/admin/user/${id}/p2p/disputes`,
		P2P_TRADES: (id: string) => `/admin/user/${id}/p2p/trades`,
		P2P_BALANCES: (id: string) => `/admin/user/${id}/p2p/balances`,
		P2P_TRANSACTIONS_WITHDRAWS: (id: string, type: string) =>
			`/admin/user/${id}/transactions?type=${type}`,
		FIAT_DEPOSIT: (id: string) => `/admin/payment/all`,
		CONVERTS: (id: string) => `/admin/user/${id}/exchanges`,
		TRANSFERS: (id: string) => `/admin/user/${id}/transfers`,
		GET_CARDS_PAYMENT: (id: string) => `/admin/user/${id}/bank_cards`,
		GET_BANK_ACCOUNT_PAYMENT: (id: string) => `/admin/user/${id}/bank_accounts`,
		UPDATE_BANK_ACCOUNT_STATUS: (id: string) => `/admin/user/${id}/bank_accounts/status`,
		UPDATE_REFERRAL: (id: string) => `/admin/user/${id}/referral_fee`,
		EMAIL_2FA: '/admin/email-2fa-history',
		SMS_2FA: '/admin/sms-2fa-history',
	},
	adminManagement: {
		ADMINS: '/admin/admins',
		PERMISSIONS: '/admin/permissions',
		CREATE_ADMIN: '/admin/admins',
		EDIT_ADMIN: (id: number) => `/admin/admin/${id}`,
		DELETE_ADMIN: (id: number) => `/admin/admin/${id}`,
		CHANGE_PASSWORD_ADMIN: (id: number) => `/admin/admins/change-password-admin/${id}`,
	},
	wallet: {
		BALANCE: '/admin/user_balances',
		HOT_BALANCE: '/admin/hot_wallets_data',
		TOTAL_FEE_COLLECTED: '/admin/total_fee_collected_data',
	},
	fee: {
		ORDER_FEES_AND_LIMITS: '/admin/pairs/fees',
		UPDATE_ORDER_FEES_AND_LIMITS: '/admin/pairs/fees',
		CRYPTO_FEES: '/admin/crypto_fees',
		UPDATE_CRYPTO_FEES: '/admin/crypto_fees',
		PLATFORM_FEES: '/admin/fees',
		UPDATE_PLATFORM_FEES: '/admin/fees',
		FIAT_FEES: '/admin/fees/fiat',
		UPDATE_FIAT_FEES: '/admin/fees',
		VOLUME_SETTINGS: '/admin/volume_settings',
		UPDATE_VOLUME_SETTINGS: '/admin/volume_settings',
		P2P_FEES: '/admin/p2p/fees',
		MARGIN_BORROW: '/admin/margin/currencies',
		MARGIN_BORROW_UPDATE: '/admin/margin/fee',
	},
	fiatFees: {
		FIAT_LIST: '/admin/fiat_fees/fiat-list',
		CHANNEL_LIST: '/admin/fiat_fees/channels',
		RANGES_PERCENT: '/admin/fiat_fees/ranges-percent',
		UPDATE_FIAT_FEE: '/admin/fiat_fees/range/percent',
		EXPORT_PERCENT_FIAT_FEE: '/admin/fiat_fees/export-range-percent',
	},
	pendingWithdrawals: {
		PENDING_WITHDRAWALS: '/admin/transactions/withdrawals/pending',
		FIAT_WITHDRAWALS: '/admin/payment/withdraw/pending',
		REJECT_WITHDRAWALS: '/admin/transactions/withdrawal',
		APPROVE_WITHDRAWALS: '/admin/transactions/withdrawal',
		APPROVE_FIAT_WITHDRAWAL: '/admin/payment/withdraw/complete',
		REJECT_FIAT_WITHDRAWAL: '/admin/payment/withdraw/cancel',
		GET_BANK_ACCOUNT: '/admin/bank_transfer/withdraw/pending',
	},
	notifications: {
		GET_ALL_NOTIFICATIONS: '/admin/notification/all',
		UPDATE_NOTIFICATIONS: (id: number) => `/admin/notification/${id}`,
	},
	tradingPairs: {
		GET_ASSETS_PAIRS: '/admin/pairs',
		UPDATE_ASSETS_PAIRS: `/admin/pairs`,
		GET_MARGIN_ISOLATED: '/admin/margin/currencies',
		TOGGLE_ACTIVE_ISOLATE: (pairId: number) => `/admin/margin/active/isolate/${pairId}`,
		TOGGLE_ACTIVE_CROSS: (id: number) => `/admin/margin/active/cross/${id}`,
		CHANGE_SHOULDER_ISOLATE: '/admin/margin/isolate/shoulder',
		CHANGE_SHOULDER_CROSS: '/admin/margin/cross/shoulder',
		GET_ASSETS: '/admin/assets',
		ADD_ASSETS_PAIR: '/admin/asset_pair',
	},
	walletManagement: {
		GET_HOT_WALLETS_DATA: '/admin/hot_wallets_data',
		GET_HOT_WALLET_ADDRESS: '/admin/get_hot_wallet_address',
		PUT_HOT_WALLETS_DATA: '/admin/hot_wallets_settings',
		WITHDRAW_TO_COLD_WALLET: '/admin/withdraw_to_cold_wallet',
		HOT_COLD_TRANSACTIONS: '/admin/hot_cold_transactions',
		GET_LIQUIDITY_DATA: '/admin/liquidity_data',
		RESET_COLD_BALANCE: '/admin/reset_cold_balance',
		GET_EXTERNAL_BALANCES_DATA: '/admin/external_liquidity/balances',
		PUT_EXTERNAL_BALANCES_DATA: '/admin/external_liquidity/balance_limits',
		GET_LIQUIDITY_SETTINGS: 'admin/external_liquidity/settings',
		PUT_LIQUIDITY_SETTINGS: 'admin/external_liquidity/settings',
		GET_WALLETS_LIST: 'admin/external_wallet',
		GET_PROVIDERS: 'admin/external_wallet/providers',
		GET_COIN_LIST_WITH_CHAINS: 'admin/external_wallet/coins',
		ADD_EXTERNAL_WALLET: 'admin/external_wallet/add_wallet',
		UPDATE_WALLET_STATUS: 'admin/external_wallet/update',
		DELETE_EXTERNAL_WALLET: (id: number) => `admin/external_wallet/delete_wallet/${id}`,
		GET_EXTERNAL_WALLET_HISTORY: 'admin/external_wallet/history',
		BANK_ACCOUNT_BALANCE: 'admin/platform_bank_account_balance',
	},
	tokenManagement: {
		TOKEN_MANAGEMENT: `/admin/token_management/info`,
		TOKEN_MANAGEMENT_HISTORY: 'admin/token_management/history',
		TOKEN_MANAGEMENT_EDIT: (url: string) => `/admin/token_management/${url}`,
		CHECK_AND_MINT: '/admin/token_management/check_and_mint',
		CHECK_AND_BURN: '/admin/token_management/check_and_burn',
		CHECK: '/admin/token_management/check',
	},
	marketMakerBot: {
		MARKET_MAKER_SETTINGS: '/admin/market_maker/settings',
		MARKET_MAKER_SETTINGS_EDIT: '/admin/market_maker/settings',
		MARKET_MAKER_TOGGLE_ENABLE: '/admin/market_maker/toggle_enabled',
		DELETE_PAIR_BOT: `/admin/cancel_bot_open_orders`,
	},
	limitations: {
		CRYPTO_FEES: '/admin/crypto_fees',
		CRYPTO_FEES_FIAT: '/admin/fiat/limits',
		UPDATE_CRYPTO_FEES: '/admin/crypto_fees',
		DAILY_WITHDRAWAL: '/admin/withdrawal_limit_settings',
		LIMITATIONS_P2P: '/admin/p2p/limitations',
		CRYPTO_SINGLE: '/admin/limitations/crypto-single',
		CRYPTO_DAILY: '/admin/limitations/crypto-daily',
		CRYPTO_MONTHLY: '/admin/limitations/crypto-monthly',
		FIAT_SINGLE: '/admin/limitations/fiat-single',
		FIAT_DAILY: '/admin/limitations/fiat-daily',
		FIAT_MONTHLY: '/admin/limitations/fiat-monthly',
	},
	transactions: {
		DEPOSITS_CRYPTO: '/admin/transactions/deposits',
		DEPOSITS_FIAT: '/admin/payment/fiat_deposits',
		WITHDRAWALS: '/admin/transactions/withdrawals',
		WITHDRAWALS_FIAT: '/admin/payment/fiat_withdrawals',
		TRANSFERS: '/admin/transactions/transfers',
		CONVERTS: '/admin/exchanges',
		CREATE_BANK_ACCOUNT: '/admin/payment/transaction',
		CREATE_BANK_ACCOUNT_CRYPTO: '/admin/transactions/deposit',
	},
	spot: {
		USER_BALANCES: (id: number) => `/admin/user/${id}/balances`,
	},
	chat: {
		POST_P2p_TRADE: `p2p/trading/trade`,
		GET_P2p_TRADE: (trade_id: string) => `p2p/trading/trade/${trade_id}`,
		GET_P2p_CHATS: (trade_id: string) => `p2p/trading/chats/${trade_id}`,
		PUT_P2p_CANSELED: (trade_id: string) => `p2p/trading/cancel_trade/${trade_id}`,
		PUT_P2p_RECEIVED: (trade_id: string) => `p2p/trading/have_received_payment/${trade_id}`,
		PUT_P2p_PAID_SELLER: (trade_id: string) => `p2p/trading/have_paid_seller/${trade_id}`,
	},
	p2pChat: {
		GET_P2p_CHATS: (trade_id: string) => `admin/p2p/disputes/chats/${trade_id}`,
		POST_MESSAGE_SELLER: `admin/p2p/disputes/message/seller`,
		POST_MESSAGE_BUYER: `admin/p2p/disputes/message/buyer`,
	},
	bankAccount: {
		BANK_ACCOUNT: 'admin/platform_bank_account',
		DELETE_BANK_ACCOUNT: (id: number) => `admin/platform_bank_account/${id}`,
	},
	externalMaker: {
		EXTERNAL_MAKER_PAIRS: 'admin/external_market',
		EXTERNAL_MAKER_PAIRS_UPDATE: (id: number) => `admin/external_market/update_pair/?id=${id}`,
		ADD_EXTERNAL_MAKER_PAIR: 'admin/external_market/add_pair',
		DELETE_EXTERNAL_PAIR: (id: number) => `admin/external_market/delete_pair/?id=${id}`,
		ORDER_HISTORY: 'admin/external_market/external_orders',
		DELETE_EXPIRED: '/admin/external_market/delete_expired',
	},
	translates: {
		PAGE_CONTENT: '/admin/page_content',
	},
	referral: {
		REFERRAL_FEE: 'admin/referral_fee',
		REFERRALS: (id: number) => `admin/referrals${id ? `/${id}` : ''}`,
		REFERRAL_TRANSACTIONS: 'admin/referrals/transactions',
		REFERRAL_EXPORT: 'admin/referrals/export',
	},
	game: {
		GAME_TRANSACTIONS: 'admin/game_transactions',
	},
	staking: {
		STAKING_INFO: '/admin/staking/info',
		STAKE_TRANSACTIONS: '/admin/staking',
		STAKE_TRON: '/admin/staking/stake',
		UNSTAKE_TRON: '/admin/staking/unstake',
	},
	marketBot: {
		SETTINGS: '/admin/market_bot/settings',
	},
	unidentifiedDeposits: {
		GET_UNIDENTIFIED_DEPOSITS: '/admin/bank_transfer/unknown_deposits',
		CONFIRM_UNIDENTIFIED_DEPOSITS: '/admin/bank_transfer/unknown_deposits/confirm',
		REJECT_UNIDENTIFIED_DEPOSITS: '/admin/bank_transfer/unknown_deposits/reject',
		FIND_USERS: '/admin/bank_transfer/unknown_deposits/find_user',
	},
	pendingDeposits: {
		ALL: '/admin/game_transactions/pending',
		APPROVE: '/admin/game_transactions/pending/approve',
		REJECT: '/admin/game_transactions/pending/reject',
	},
};
