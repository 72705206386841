/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApproveWithdrawalsRequestPayload,
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsRequestPayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsListRequestPayload,
	IWithdrawalsResponsePayload,
	IWithdrawalsStore,
	IGetBankTransferPayload,
	IGetBankTransferResponse,
} from './types';
// ==========================================:
export const initialState: IWithdrawalsStore = {
	withdrawals: null,
	withdrawalsLoader: false,
	approveWithdrawals: null,
	approveWithdrawalsLoader: false,
	rejectWithdrawals: null,
	rejectWithdrawalsLoader: false,
	withdrawalsFiatLoader: false,
	withdrawalsFiat: null,
	bankTransferLoader: false,
	bankTransfer: null,
};

// ==========================================:
const withdrawals = createSlice({
	name: '@@withdrawals',
	initialState,
	reducers: {
		getWithdrawalsRequest: (state, action: PayloadAction<IWithdrawalsListRequestPayload>) => {
			const withdrawalsRequestState = state;
			withdrawalsRequestState.withdrawalsLoader = true;
		},
		getWithdrawalsSuccess: (state, action: PayloadAction<IWithdrawalsResponsePayload>) => {
			const { payload } = action;
			const withdrawalsSuccessState = state;
			withdrawalsSuccessState.withdrawals = payload;
			withdrawalsSuccessState.withdrawalsLoader = false;
		},

		approveWithdrawalsRequest: (
			state,
			action: PayloadAction<IApproveWithdrawalsRequestPayload>,
		) => {
			const { payload } = action;
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = true;
		},
		approveWithdrawalsSuccess: (
			state,
			action: PayloadAction<IApproveWithdrawalsResponsePayload>,
		) => {
			const { payload } = action;
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = false;
		},

		rejectWithdrawalsRequest: (state, action: PayloadAction<IRejectWithdrawalsRequestPayload>) => {
			const { payload } = action;
			const rejectWithdrawalsRequestState = state;
			rejectWithdrawalsRequestState.rejectWithdrawalsLoader = true;
		},
		rejectWithdrawalsSuccess: (state, action: PayloadAction<IRejectWithdrawalsResponsePayload>) => {
			const { payload } = action;
			const rejectWithdrawalsRequestState = state;
			rejectWithdrawalsRequestState.rejectWithdrawalsLoader = false;
		},
		getFiatWithdrawalsRequest: (state, action) => {
			const withdrawalsState = state;
			withdrawalsState.withdrawalsFiatLoader = true;
		},
		getFiatWithdrawalsSuccess: (state, action) => {
			const { payload } = action;
			const withdrawalsState = state;
			withdrawalsState.withdrawalsFiatLoader = false;
			withdrawalsState.withdrawalsFiat = payload;
		},
		approveFiatWithdrawalsRequest: (
			state,
			action: PayloadAction<IApproveWithdrawalsRequestPayload>,
		) => {
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = true;
		},
		approveFiatWithdrawalsSuccess: (state, action) => {
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = false;
		},
		rejectFiatWithdrawalsRequest: (
			state,
			action: PayloadAction<IRejectWithdrawalsRequestPayload>,
		) => {
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = true;
		},
		rejectFiatWithdrawalsSuccess: (state, action) => {
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = false;
		},
		getBankTransferRequest: (state, action: PayloadAction<IGetBankTransferPayload>) => {
			const bankTransferState = state;
			bankTransferState.bankTransferLoader = true;
		},
		getBankTransferSuccess: (state, { payload }: PayloadAction<IGetBankTransferResponse>) => {
			const bankTransferState = state;
			bankTransferState.bankTransferLoader = false;
			bankTransferState.bankTransfer = payload;
		},
		getBankTransferError: (state) => {
			const bankTransferState = state;
			bankTransferState.bankTransferLoader = false;
		},
		withdrawalsInitState: () => initialState,
	},
});

export default withdrawals.reducer;
export const {
	getWithdrawalsRequest,
	getWithdrawalsSuccess,
	approveWithdrawalsRequest,
	approveWithdrawalsSuccess,
	rejectWithdrawalsRequest,
	rejectWithdrawalsSuccess,
	getFiatWithdrawalsRequest,
	getFiatWithdrawalsSuccess,
	approveFiatWithdrawalsRequest,
	approveFiatWithdrawalsSuccess,
	rejectFiatWithdrawalsRequest,
	rejectFiatWithdrawalsSuccess,
	getBankTransferRequest,
	getBankTransferSuccess,
	getBankTransferError,
	withdrawalsInitState,
} = withdrawals.actions;
