/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IPendingDepositsStore,
	IGetPendingDepositsRequest,
	IGetPendingDepositsResponse,
	IFilterParams,
	IApproveRequestPayload,
	IRejectRequestPayload,
} from './types';
// ==========================================:

export const initialState: IPendingDepositsStore = {
	pendingDepositsLoading: false,
	pendingDeposits: null,
	filterParams: null,
	approveLoader: false,
	rejectLoader: false,
};

// ==========================================:

const pendingDeposits = createSlice({
	name: '@@pendingDeposits',
	initialState,
	reducers: {
		getPendingDepositsRequest: (state, { payload }: PayloadAction<IGetPendingDepositsRequest>) => {
			const pendingDepositsState = state;
			if (payload.export !== 1) {
				pendingDepositsState.pendingDepositsLoading = true;
			}
		},
		getPendingDepositsSuccess: (state, { payload }: PayloadAction<IGetPendingDepositsResponse>) => {
			const pendingDepositsState = state;
			pendingDepositsState.pendingDepositsLoading = false;
			pendingDepositsState.pendingDeposits = payload;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const pendingDepositsState = state;
			pendingDepositsState.filterParams = payload;
		},
		approveDepositRequest: (state, action: PayloadAction<IApproveRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveLoader = true;
		},
		approveDepositSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveLoader = false;
			if (!pendingDepositsState.pendingDeposits?.data) {
				return;
			}
			pendingDepositsState.pendingDeposits.data = pendingDepositsState.pendingDeposits.data.filter(
				(item) => {
					return item.id !== payload.id;
				},
			);
		},
		rejectDepositRequest: (state, action: PayloadAction<IRejectRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectLoader = true;
		},
		rejectDepositSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectLoader = false;
			if (!pendingDepositsState.pendingDeposits?.data) {
				return;
			}
			pendingDepositsState.pendingDeposits.data = pendingDepositsState.pendingDeposits.data.filter(
				(item) => {
					return item.id !== payload.id;
				},
			);
		},
		initState: () => initialState,
	},
});

export default pendingDeposits.reducer;
export const {
	getPendingDepositsRequest,
	getPendingDepositsSuccess,
	setFilterParams,
	approveDepositRequest,
	approveDepositSuccess,
	rejectDepositRequest,
	rejectDepositSuccess,
} = pendingDeposits.actions;
