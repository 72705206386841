import {
	IConvertsTransactionsResponsePayload,
	ICryptoTransactionsResponsePayload,
	IFiatTransactionsResponsePayload,
	ITransfersTransactionsResponsePayload,
	IWithdrawalsTransactionsResponsePayload,
	ICreateTransactionBankAccountPaylod,
	IReferralTransactionsResponsePayload,
	IGameTransactionsResponsePayload,
} from 'redux/reducers/transactions/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITransactionsApi } from './types';

// ==========================================:
export const transactions: ITransactionsApi = {
	getCryptoTransactions: (params) =>
		http
			.get<ICryptoTransactionsResponsePayload>(endpoint.transactions.DEPOSITS_CRYPTO, { params })
			.then((response) => response.data),
	getFiatTransactions: (params) =>
		http
			.get<IFiatTransactionsResponsePayload>(endpoint.transactions.DEPOSITS_FIAT, { params })
			.then((response) => response.data),
	getWithdrawalsTransactions: (params) =>
		http
			.get<IWithdrawalsTransactionsResponsePayload>(endpoint.transactions.WITHDRAWALS, { params })
			.then((response) => response.data),
	getWithdrawalsTransactionsFiat: (params) =>
		http
			.get<any>(endpoint.transactions.WITHDRAWALS_FIAT, { params })
			.then((response) => response.data),
	getTransfersTransactions: (params) =>
		http
			.get<ITransfersTransactionsResponsePayload>(endpoint.transactions.TRANSFERS, { params })
			.then((response) => response.data),
	getConvertsTransactions: (params) =>
		http
			.get<IConvertsTransactionsResponsePayload>(endpoint.transactions.CONVERTS, { params })
			.then((response) => response.data),
	getReferralTransactions: (params) =>
		http
			.get<IReferralTransactionsResponsePayload>(endpoint.referral.REFERRAL_TRANSACTIONS, {
				params,
			})
			.then((response) => response.data),

	getGameTransactions: (params) =>
		http
			.get<IGameTransactionsResponsePayload>(endpoint.game.GAME_TRANSACTIONS, {
				params,
			})
			.then((response) => response.data),

	createBankAccount: (payload) =>
		http
			.post<any>(endpoint.transactions.CREATE_BANK_ACCOUNT, {
				...payload,
			})
			.then((response) => response.data),
	createBankAccountCrypto: (payload) =>
		http
			.post<any>(endpoint.transactions.CREATE_BANK_ACCOUNT_CRYPTO, {
				...payload,
			})
			.then((response) => response.data),
};
