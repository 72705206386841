/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IDepositItemProps } from './types';
import { ActionsButtons } from './ActionButtons';

// ==========================================:
export const DepositItem: FC<IDepositItemProps> = ({ data, handleClearSearch }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User Id</p>
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link className="td" to={`/user-management/${String(data.user_id)}`}>
						<p className="td-hidden-name">Id</p>
						<p>{data.user_id}</p>
					</Link>
				) : (
					<div className="td">
						<p className="td-hidden-name">Id</p>
						<p>{data.user_id}</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Game User ID</p>
				<p>{data.game_user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data.asset_code?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{data.amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount in USD</p>
				<p>{Number(data.amount_usd)}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<ActionsButtons id={data.id} handleClearSearch={handleClearSearch} />
			</div>
		</div>
	);
};
