import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Withdrawals from 'components/Withdrawals';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const PendingDepositsPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_PENDING_DEPOSITS}>
			<Dashboard title="Pending deposits">
				<Withdrawals />
			</Dashboard>
		</PermissionGate>
	);
};

export default PendingDepositsPage;
